import CookieConsent from '@grrr/cookie-consent';

$( document ).ready(function() {

    const cookieConsent = CookieConsent({
        type: 'checkbox',
        acceptAllButton: false,
        cookies: [
            {
                id: 'functional',
                label: 'Functional cookies',
                description: 'Makes the site work for you. Doesn\'t identify or track you.',
                required: true,
                checked: true,
            },
            {
                id: 'analytics_storage',
                label: 'Analytics cookies',
                description: 'Statistics about our visitors. Doesn\'t identify you.',
                checked: false,
            },
            {
                id: 'ad_storage',
                label: 'Marketing cookies',
                description: 'Google AdWords cookies. Allows us to see which ads are working. Doesn\'t identify you.',
                checked: false,
            },
            {
                id: 'ad_user_data',
                label: 'Advertising data',
                description: 'Advertising - use this site visit',
                checked: false,
            },
            {
                id: 'ad_personalization',
                label: 'Advertising personalization',
                description: 'Advertisting - Track this site visit',
                checked: false,
            },
        ],
        labels: {
            title: 'Cookies & Privacy',
            description: `<p>We will use some third-party cookies if you allow it. Read more in our <a href="/privacy">privacy policy</a>.</p>`
        }
    });

    function gtag(){dataLayer.push(arguments);}

    let cookieDialog = document.getElementById('cookies');
    cookieDialog.addEventListener('click', e => {
        e.preventDefault();
        cookieConsent.showDialog();
    });

    cookieConsent.on('update', cookies => {
        const dataLayer = window.dataLayer || [];
        cookies.forEach(cookie => gtag(
            'consent',
            'update',
            {
                [cookie.id]: cookie.accepted ? 'granted' : 'denied',
                'wait_for_update': 500
            }
        ));
    });

})